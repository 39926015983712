import scrollama from "scrollama";

if (document.querySelector('.animatev') && window.innerWidth > 1200) {

    const vScroller = scrollama();
    const vid = document.querySelector('.animatev video');
    const items = document.querySelectorAll('.animatev .item-content');

    function handleResize() {
        vScroller.resize();
    }
    let videoDuration = vid.dataset.duration;
    let time = 0;
    let lastPosition;

    function init() {
        handleResize();
        vScroller
            .setup({
                step: ".animatev article .item",
                offset: 0.9,
                debug: false,
                progress: true
            })
            .onStepProgress((response) => {
                let progress = response.progress;
                time = progress.toFixed(2);
                items.forEach((item) => {
                    item.classList.remove('active');
                })
                if (time > 0 && time < 0.3) {
                    items[0].classList.add('active');
                }
                if (time > 0.3 && time < 0.5) {
                    items[1].classList.add('active');
                }
                if (time > 0.5 && time < 0.7) {
                    items[2].classList.add('active');
                }
                if (time > 0.6 && time < 0.95) {
                    items[3].classList.add('active');
                }
            })
            .onStepEnter((response) => {
                setInterval(() => {
                    if (lastPosition === time) return;
                    requestAnimationFrame(() => {
                        vid.currentTime = videoDuration * time;
                        vid.pause();
                        lastPosition = time;
                    });
                }, 50);
            })
            .onStepExit((response) => {

            });
    }
    init();

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    const debouncedHandleResize = debounce(() => {
        if (window.innerWidth > 1200) {
            handleResize();
        }
    }, 200);

    window.addEventListener('resize', debouncedHandleResize);

}
