

function throttle(fn, wait) {
    var time = Date.now();
    return function() {
        if ((time + wait - Date.now()) < 0) {
            fn();
            time = Date.now();
        }
    }
}

document.addEventListener("DOMContentLoaded", function() {

    const header = document.querySelector('header.site-header');
    const menuTrigger = document.querySelector('.menuTrigger');
    const upEl = document.getElementById('up');
    const frontVideo = document.querySelector('main.front .responsive-video video');

    const modals = document.querySelectorAll('.modal');
    const videoWrappers = document.querySelectorAll('.video_wrapper.open-modal .preview');
    const body = document.querySelector('body');

    document.body.addEventListener('click', function(e) {
        if (e.target.matches('.menuTrigger')) {
            header.classList.toggle('is-open');
        } else if (e.target.matches('.modal .close')) {
            let modal = e.target.closest('.modal');
            if (modal.id === 'videoModal') {
                modal.querySelector('.video').setAttribute('src', null);
            }
            e.preventDefault();
            modal.classList.remove('visible');
        }
        // Add other event delegation cases
    });

    window.addEventListener('scroll', throttle(function() {
        upEl.classList.toggle('active', (window.scrollY > 100));
        header.classList.toggle('scroll', (window.scrollY > 80));
        if (frontVideo && window.scrollY > 500) {
            frontVideo.play();
        }
    }, 100));


    upEl.addEventListener('click', function() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.classList.remove('show');
    });



    /*var heroArrow = document.querySelector('.hero .arrow');
    if (heroArrow) {
        heroArrow.addEventListener('click', function(e) {
            window.scrollTo({
                top: document.querySelector('.hero + section').offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        })
    } */

    modals.forEach(modal => {
        modal.querySelector('.close').addEventListener('click', function(e) {
            if (modal.id === 'videoModal') {
                modal.querySelector('.video').setAttribute('src', null);
            }
            e.preventDefault();
            modal.classList.remove('visible');
        });
    });

    if (videoWrappers.length) {
        const videoModal = document.querySelector('#videoModal');
        videoWrappers.forEach(videoWrapper => {
            videoWrapper.addEventListener('click', e => {
                e.preventDefault();
                videoModal.classList.add('visible');
                const video = videoModal.querySelector('video');
                const src = window.innerWidth < 800 ? videoWrapper.dataset.src_mobile : videoWrapper.dataset.src;
                video.setAttribute('src', src);
                video.play();
            });
        });
    }

    document.querySelector('body').classList.remove('preload');
});

jQuery(document).ready(function($) {


    $('.video-section .play').on('click', function() {
        var videoWrapper = $(this).closest('.video-section');
        videoWrapper.find('.preview').fadeOut();
        videoWrapper.addClass('hidden');

        videoWrapper.find('iframe').attr('src', this.dataset.src);
        videoWrapper.find('iframe').fadeIn();

    })


    $(".categories-menu").appendTo(".products-menu");

    $('.toggle-cart').on('click', function(e) {
        $('.woo-minicart').toggleClass('active');
    })
    $('.woo-minicart .close').on('click', function(e) {
        $('.woo-minicart').removeClass('active');
    })
    $(document).on('click', 'button.add, button.subtract', function() {
        var qty = $(this).parent('.quantity').find('.qty');
        var val = parseFloat(qty.val());
        var max = parseFloat(qty.attr('max'));
        var min = parseFloat(qty.attr('min'));
        var step = parseFloat(qty.attr('step'));

        if ($(this).is('.add')) {
            if (max && (max <= val)) {
                qty.val(max).change();
            } else {
                qty.val(val + step).change();
            }
        } else {
            if (min && (min >= val)) {
                qty.val(min).change();
            } else if (val > 1) {
                qty.val(val - step).change();
            }
        }

    });


    $('.featured-categories a').on('click', function(e) {
        var available = $(this).data('available');
        if (available) {
            alert(available);
            e.preventDefault();
        }
    })

    if ($('.single-product').length) {
        if ($('.product_tag-out-of-stock').length || $('p.out-of-stock').length || $('.single-product .outofstock').length) {
            $('.woocommerce-Tabs-panel--nutrition_tab').insertAfter('.woocommerce-Tabs-panel--description');
            $('.woocommerce-Tabs-panel--reviews').insertAfter('.woocommerce-Tabs-panel--description');
        }
    }

    //contact
    $('.contact_us a').on('click', function(e) {
        e.preventDefault();
        let data = {
            'action': 'loadContact'
        };
        let text = $(this).text();
        $(this).text('Loading...');
        $('.loader').show();
        $.post(woocommerce_params.ajax_url, data, function(response) {
            $('#contactModal').addClass('visible');
            $('#contactModal .modal-content').html(response);
            wpcf7.init(document.querySelector('#contactModal .wpcf7-form'));
            $('.loader').hide();
            $('.contact_us a').text(text);
        });
    })

    $('.contact_b2b a').on('click', function(e) {
        e.preventDefault();
        var data = {
            'action': 'loadB2bContact'
        };
        let text = $(this).text();
        $(this).text('Loading...');
        $('.loader').show();
        $.post(woocommerce_params.ajax_url, data, function(response) {
            $('#contactModal').addClass('visible');
            $('#contactModal .modal-content').html(response);
            wpcf7.init(document.querySelector('#contactModal .wpcf7-form'));
            $('.loader').hide();
            $('.contact_b2b a').text(text);
        });
    })

    /*if ($('main.single').length) {
        Shareon.init();
    } */

    $('main.kids .usp-item h3').on('click', function() {
        $(this).toggleClass('open');
        $(this).closest('.usp-item').find('.usp-item__content').slideToggle();
    })

    /*$('.toggleFilter').on('click', function() {
        $('.prdctfltr-widget').slideToggle();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    })*/

    $('div.product a.view-desc').on('click', function(e) {
        e.preventDefault();

        window.scrollTo({
            top: document.querySelector('.woocommerce-tabs').offsetTop + 80,
            left: 0,
            behavior: 'smooth'
        });

    })

    $('.schema-faq-question').on('click', function() {
        $(this).closest('.schema-faq-section').find('.schema-faq-answer').slideToggle();
    })

    $('body').on('updated_checkout', function(e) {
        var data = {
            'action': 'loadFreeShipping'
        };
        $.post(woocommerce_params.ajax_url, data, function(response) {
            $('.free-shipping-wrapper').html(response);
        });
    })

    $('.filter-categories .widgettitle').on('click', function(e) {
        $('.filter-categories').toggleClass('active');
    })

    $(document).on('change', 'input.qty', function(){
        $('[name=\'update_cart\']').attr('disabled', false);
        $('[name=\'update_cart\']').trigger('click');
    });

    $('.products-menu > a').on('click', function(e) {
        if (window.innerWidth < 1024) {
            e.preventDefault();
            e.stopPropagation();
            $('.products-menu .sub-menu').slideToggle();
        }
    })

    $('.single-product .single_add_to_cart_button').on('click', function() {
        $(this).text('Loading...')
    })

    $(document).on('change', '#billing_vatno', function() {
        $('body').trigger('update_checkout');
    })

    let billingCompany = $('#billing_company');
    if (billingCompany.length) {
        if (billingCompany.val().length > 2) {
            $('#billing_vatno_field').fadeIn();
        }
    }

    $(document).on('change', '#billing_company', function() {
        let billingVatField = $('#billing_vatno_field');
        if ($(this).val().length > 2) {
            billingVatField.fadeIn();
        } else {
            $('#billing_vatno').val('');
            billingVatField.hide();

        }
        $('body').trigger('update_checkout');

    })

    $( 'body' ).on( 'updated_checkout', function() {
        toggleVatTxt();

        let country = $('#billing_country').val();
        let billingPackstationField = $('#billing_packstation_field');
        let billingDhlPostnoField = $('#billing_dhl_postnumber_field');
        if (country === 'DE') {
            billingPackstationField.fadeIn();
            billingDhlPostnoField.fadeIn();
        }
    });

    $(document).on('change', '#billing_country', function() {
        toggleVatTxt();
        let billingPackstationField = $('#billing_packstation_field');
        let billingDhlPostnoField = $('#billing_dhl_postnumber_field');

        if ($(this).val() === 'DE') {
            billingPackstationField.fadeIn();
            billingDhlPostnoField.fadeIn();
        } else {
            $('#billing_packstation').val('');
            $('#billing_dhl_postnumbe').val('');
            billingPackstationField.hide();
            billingDhlPostnoField.hide();
            $('body').trigger('update_checkout');
        }
    })

    function toggleVatTxt()
    {
        $('.price-suffix.vat').hide();
        let code = $('#billing_country').val();
        let vatNo = $('#billing_vatno').val();
        let euc = ['AT','BE','BG','CY','CZ','DE','DK','EE','ES','FI','FR','GR','HR','HU','IE','IT','LT','LU','LV','MT','NL','PL','PT','RO','SE','SI','SK','NO','CH'];
        if (euc.includes(code) && !vatNo.length) {
            $('.price-suffix.vat').show();
            $('.price-suffix.no-vat').hide();
        } else {
            $('.price-suffix.vat').hide();
            $('.price-suffix.no-vat').show();
        }
        if (euc.includes(code) && code == 'DE') {
            $('.price-suffix.vat').show();
            $('.price-suffix.no-vat').hide();
        }
    }

    let accordionElement = document.querySelector('.accordion');
    if (accordionElement) {
        $('.accordion__item .accordion__header').on('click', function() {
            $(this).toggleClass('active');
            $(this).closest('.accordion__item').find('.accordion__content').slideToggle();
        })
    }

    // NEW CODE
    $('.localize-layer .country-select select').on('change', function() {
        $('.local-info-content').removeClass('active');
        let selectedLocale = $(this).val();
        let selectedLocaleInfo = $('.local-info-content[data-locale="' + selectedLocale + '"]');
        selectedLocaleInfo.addClass('active');
        let flag = $(this).find(':selected').data('flag');
        $(this).closest('.country-select').find('.flag').attr('src', flag);

    })

    $('.localize-layer .btn').on('click', function() {
        let ajaxUrl = woocommerce_params.ajax_url;
        let requestData = {
            'action': 'setShopLocale',
            'country': $('.country-select #country').val(),
            'lang': $('.languages').val()
        };

        jQuery.post(ajaxUrl, requestData, function (res) {
           window.location =  $('.languages').find(':selected').data('url');
        });
    })

    $('.localize').on('click', function() {
        $('.localize-layer').addClass('active');
    })

    $('.localize-layer .closeLayer').on('click', function() {
        $('.localize-layer').removeClass('active');
    })

    // testimonials
    $('.read-more').on('click', function(e){
        e.preventDefault();
        $(this).hide();
        let textElement = $(this).closest('.text');
        textElement.find('span.separator').hide();
        textElement.find('.all-text').addClass('visible');
    })

    // product filter
    $('.woof_container_inner h4').on('click', function() {
        $(this).toggleClass('collapsed');
        $(this).closest('.woof_container_inner').find('.woof_block_html_items').slideToggle();
    })

    // modals
    /*let modals = document.querySelectorAll('.modal');
    for (let i = 0; i < modals.length; i++) {
        let modalClose = modals[i].querySelector('.close');
        modalClose.addEventListener('click', function(e) {
            e.preventDefault();
            this.closest('.modal').classList.remove('visible');
        })
    }

    let videos = document.querySelectorAll('.wp-block-video video');
    for(let i = 0; i < videos.length; i++) {
        //videos[i].play();
    } */

    $(document).on('click', '.load-more-products', function() {
        $(this).hide();
        $('.cart_list .cart_item').show();
    })

    $(document).on('change', '.order-products .quantity input', function() {
        let ckey = $(this).closest('.qty-wrap').data('key');
        let qty = parseInt($(this).val());
        $('#minicart-widget [data-key="'+ ckey + '"] input').val(qty).trigger('change');
    })

    $(document).on('click', '.order-products .remove', function(e) {
        e.preventDefault();
        let ckey = $(this).closest('.qty-wrap').data('key');
        $('#minicart-widget [data-key="'+ ckey + '"] input').val(0).trigger('change');
    })

    jQuery('body').on('wc_fragments_refreshed', function(){
        $(document.body).trigger("update_checkout");
    });

    // update toggle-cart count on page load via ajax
    let data = {
        'action': 'getCartCount'
    };
    $.post(woocommerce_params.ajax_url, data, function(response) {
        if (response > 0) {
            $('.toggle-cart .count').text(response).show();
        } else {
            $('.toggle-cart .count').hide();
        }

    });






});
