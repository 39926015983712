import './scss/styles.scss';

import './js/aos';
import './js/lazyload';
import './js/main';
import './js/app';
import './js/search';
import './js/slider';

import './js/scrolling-video';
import './js/cookieconsent-init';


import './js/product';
import './js/scrolling-tabs';

// Dynamice imports for perfomance optimization

if (document.body.classList.contains('page-template-page-product_picker')) {
    import('./js/product-picker').then(ProductPicker => {
        console.log('product-picker loaded');
        ProductPicker.default.init();
    })
}


